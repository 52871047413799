import { Box, Checkbox, SxProps, Tooltip, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { useUserContext } from 'contexts/users/User.context';
import { JuiceboxFilterButton } from 'features/juicebox/components';
import { JuiceboxFilter, JuiceboxFilterType } from 'features/juicebox/hooks';
import { UserFilterType, UserProfileModel } from 'graphql/generated';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { BillingOrganizationMemberLimitIndicator } from 'features/billing';

interface Props {
  organizationId?: string;
  selectedUsers: JuiceboxFilter[];
  setSelectedUsers: Dispatch<SetStateAction<JuiceboxFilter[]>>;
  componentsProps?: {
    filterButton?: {
      sx?: SxProps;
    };
  };
}

export const JuiceboxPeopleSelectButtonFilter = (props: Props) => {
  const {
    organizationId,
    selectedUsers,
    setSelectedUsers,
    componentsProps = {},
  } = props;

  const { user, orgBilling, joinedOrgBillings } = useUserContext();

  const collectionOrgBilling = useMemo(() => {
    if (!organizationId || organizationId === orgBilling?.organizationId) {
      return orgBilling;
    }

    return (
      joinedOrgBillings?.find((j) => j.organizationId === organizationId) ??
      orgBilling
    );
  }, [organizationId, orgBilling, joinedOrgBillings]);

  const isOrganizationNotAllowedMembers =
    collectionOrgBilling?.organizationMemberLimit !== null &&
    collectionOrgBilling?.organizationMemberLimit !== undefined &&
    collectionOrgBilling?.organizationMemberLimit <= 1;

  const users = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];
  const nestedOptions = [
    { text: 'All', value: '' },
    { text: 'Created by', value: UserFilterType.CreatedBy },
    { text: 'Shared by', value: UserFilterType.SharedBy },
  ];

  const autocompleteOptions = users.map((u) => ({
    label: `${getFullName(u)} ${u.email}`,
    value: u.id,
  }));

  return (
    <AutocompletePopup
      options={autocompleteOptions}
      disabled={isOrganizationNotAllowedMembers}
      renderTrigger={() => (
        <Tooltip
          title={
            !isOrganizationNotAllowedMembers ? (
              ''
            ) : (
              <BillingOrganizationMemberLimitIndicator
                organizationBilling={collectionOrgBilling}
                variant="text"
                customText="to enable this filter"
              />
            )
          }
        >
          <Box>
            <JuiceboxFilterButton
              Icon={IconLinearUser}
              disabled={isOrganizationNotAllowedMembers}
              text="People"
              sx={componentsProps.filterButton?.sx}
            />
          </Box>
        </Tooltip>
      )}
      multiple
      renderOption={(props, option) => {
        const user = users.find((u) => u.id === option.value);
        if (!user) {
          return null;
        }

        return (
          <Tooltip
            title={nestedOptions.map((nestedOption) => (
              <Box
                sx={{
                  padding: theme.spacing(2.5, 3),
                  borderRadius: 3,
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: `rgba(35, 6, 3, 0.1)`,
                  },
                }}
                key={nestedOption + user.id}
                onClick={() => {
                  const existingUserIndex = selectedUsers.findIndex(
                    (u) => u.value === user.id,
                  );

                  if (existingUserIndex !== -1) {
                    const updatedUsers = [...selectedUsers];
                    updatedUsers[existingUserIndex] = {
                      ...selectedUsers[existingUserIndex],
                      itemType: nestedOption.value as UserFilterType,
                    };
                    setSelectedUsers(updatedUsers);
                  } else {
                    setSelectedUsers([
                      ...selectedUsers,
                      {
                        filterType: JuiceboxFilterType.Users,
                        value: user.id,
                        item: user as unknown as UserProfileModel,
                        itemType: nestedOption.value as UserFilterType,
                      },
                    ]);
                  }
                }}
              >
                <Typography
                  variant="subhead-lg"
                  color={theme.colors?.primary.black}
                >
                  {nestedOption.text}
                </Typography>
              </Box>
            ))}
            placement="right-start"
            componentsProps={{
              tooltip: {
                style: {
                  minWidth: 266,
                  borderRadius: theme.spacing(3),
                  backgroundColor: theme.colors?.primary.white,
                  padding: theme.spacing(3, 3),
                  boxShadow:
                    '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
                },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
                padding: theme.spacing(2, 3),
                borderRadius: 3,
                mt: 1,
                ':hover': {
                  backgroundColor: `rgba(35, 6, 3, 0.1)`,
                },
              }}
              key={user.id}
              onClick={() => {
                setSelectedUsers((prev) => {
                  if (prev.some((f) => f.value === user.id)) {
                    return prev.filter((f) => f.value !== user.id);
                  }
                  return [
                    ...prev,
                    {
                      filterType: JuiceboxFilterType.Users,
                      value: user.id,
                      item: user as unknown as UserProfileModel,
                    },
                  ];
                });
              }}
            >
              <Checkbox
                checked={selectedUsers.some((u) => u.value === user.id)}
                sx={{
                  padding: 0,
                  '& svg': {
                    width: '16px',
                    height: '16px',
                  },
                  '&.Mui-checked': { color: theme.colors?.primary.black },
                }}
              />
              <Avatar user={user} size={20} />
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="subhead-md"
                  fontWeight={600}
                  color={theme.colors?.utility[900]}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {getFullName(user)}
                </Typography>
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.utility[700]}
                >
                  {user.email}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <IconLinearArrowRight1
                  size={16}
                  color={theme.colors?.primary.black}
                />
              </Box>
            </Box>
          </Tooltip>
        );
      }}
      placeholder="Search"
      popoverProps={{
        sx: {
          '& .MuiPaper-root': {
            boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
          },
        },
        PaperProps: {
          sx: {
            minWidth: 266,
            border: 'none',
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: 'blur(20px)',
            '& ul': {
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 0,
              msOverflowStyle: 'none',
            },
          },
        },
      }}
      inputSx={{
        border: 'none',
        '& .MuiInputBase-root': {
          padding: `${theme.spacing(2, 3)} !important`,
          ...theme.typography['subhead-lg'],
          color: theme.colors?.utility[1100],
        },
      }}
    />
  );
};
