import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { toast } from 'components/common/Toast';
import { IconCustomPinOutline } from 'components/icons/components/custom/IconCustomPinOutline';
import { IconLinearArrowDown2 } from 'components/icons/components/linear/IconLinearArrowDown2';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { IconLinearSaveRemove } from 'components/icons/components/linear/IconLinearSaveRemove';
import { IconOutlineArrowSwapHorizontal } from 'components/icons/components/outline/IconOutlineArrowSwapHorizontal';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { IconOutlineInfoCircle } from 'components/icons/components/outline/IconOutlineInfoCircle';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useCollectionHandlers } from 'features/collection/components/contextMenu/useCollectionHandlers';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import { usePostPermissionDialogView } from 'features/post-permission';
import { usePostMovedSnackbar } from 'features/post/hooks';
import { useMoveToAnotherCollectionModalForPost } from 'features/post/hooks/useMoveToAnotherCollectionModalForPost';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigatePostSearchParams';
import {
  isPostDownloadable,
  isPostPinnedToCollection,
} from 'features/post/utils';
import {
  CollectionPermission,
  PostFragmentCollectionThumbnailFragmentDoc,
  PostFragmentPostBookmarkButtonFragmentDoc,
  PostFragmentPostContextMenuFragment,
  PostFragmentPostMoveToAnotherCollectionFragmentDoc,
  PostFragmentPostPinFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostSaveToContentCalendarButtonFragmentDoc,
  PostPermission,
  PostType,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useCollectionPermissions } from 'hooks/permissions/useCollectionPermissions';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useLocation, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { PostBookmarkPopover } from '../bookmarkButton/PostBookmarkPopover';
import { usePostHandlers } from './usePostHandlers';

// eslint-disable-next-line
gql`
  fragment PostFragmentPostContextMenu on PostModel {
    id
    type
    myPermissions
    isFavoriteTrend
    trend {
      id
      title
    }
    ...PostFragmentCollectionThumbnail
    ...PostFragmentPostBookmarkButton
    ...PostFragmentPostPin
    ...PostFragmentPostPreview
    ...PostFragmentPostMoveToAnotherCollection
    ...PostFragmentPostSaveToContentCalendarButton
  }
  ${PostFragmentPostBookmarkButtonFragmentDoc}
  ${PostFragmentCollectionThumbnailFragmentDoc}
  ${PostFragmentPostPinFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostMoveToAnotherCollectionFragmentDoc}
  ${PostFragmentPostSaveToContentCalendarButtonFragmentDoc}
`;

type PostContextMenuProps = Omit<ContextMenuProps, 'options' | 'renderButton'> &
  Partial<Pick<ContextMenuProps, 'renderButton'>> & {
    post: PostFragmentPostContextMenuFragment;
    currentCollectionId?: string;
    hideMenuButton?: boolean;
    // context determines where the post is being rendered
    context?: 'post' | 'collection';
    options?: {
      movePost?: boolean;
      organize?: boolean;
      share?: boolean;
      download?: boolean;
      pin?: boolean;
      duplicate?: boolean;
      setCover?: boolean;
      delete?: boolean;
      saveToContentCalendar?: boolean;
      removeFromCollection?: boolean;
    };
  };

export const PostContextMenu = (props: PostContextMenuProps) => {
  const {
    post,
    currentCollectionId,
    hideMenuButton,
    context,
    options = {
      movePost: true,
      organize: true,
      share: true,
      download: true,
      pin: true,
      duplicate: true,
      setCover: true,
      delete: true,
      removeFromCollection: true,
    },
    ...rest
  } = props;

  const location = useLocation();
  const navigate = useGuardNavigate();

  const locationState = location.state as {
    backgroundLocation?: Location;
  };

  const { collectionId } = useCollectionIdFromParams();
  const collectionPermissions = useCollectionPermissions({
    collectionId,
  });
  const canEditCollection = collectionPermissions.permissions.includes(
    CollectionPermission.Update,
  );

  const canDelete = post.myPermissions?.includes(PostPermission.Delete);

  const { onDuplicatePost, onDeletePost, onRemovePostFromCollection } =
    usePostHandlers();
  const { onCollectionPreviewUpdate } = useCollectionHandlers();
  const { dialog: deletePostDialog, onOpen: openDeletePostConfirmationDialog } =
    useConfirmationDialog();
  const { nextPost } = useNavigatePostSearchParams();
  const [params] = useSearchParams();

  const { triggerCommand } = useCommandContext();

  const { onPinManyPostsToCollection, onUnpinManyPostsFromCollection } =
    usePinnedEntityMutations();
  const isPostPinToCollection = isPostPinnedToCollection(
    post,
    collectionId ?? '',
  );

  // Controls for post bookmark flow
  const {
    isOpen: isPostBookmarkPopoverOpen,
    onOpen: openPostBookmarkPopover,
    onClose: closePostBookmarkPopover,
  } = useDisclosure();

  const client = useApolloClient();

  const {
    renderSnackbar: renderPostMovedSnackbar,
    showSnackbar: showPostMovedSnackbar,
  } = usePostMovedSnackbar();

  const {
    openDialog: showPostPermissionDialog,
    renderContent: renderPostPermissionDialogView,
  } = usePostPermissionDialogView({
    postId: post.id,
  });

  const { hideMovePost, renderMoveToAnotherPostModal, showMovePost } =
    useMoveToAnotherCollectionModalForPost({
      post,
      currentCollectionId: currentCollectionId ?? '',
      onMoved: (toCollectionId) => {
        hideMovePost();
        showPostMovedSnackbar(post, toCollectionId);
        evictObject(client.cache, post.id, 'PostModel');
      },
    });

  const menuOptions = [
    ...(!options?.movePost || !currentCollectionId
      ? []
      : [
          {
            title: 'Move Post',
            icon: IconOutlineArrowSwapHorizontal,
            onClick: showMovePost,
            closeOnClick: false,
          },
        ]),
    ...(!options?.organize
      ? []
      : [
          {
            title: 'Organize',
            icon: IconLinearSave,
            onClick: openPostBookmarkPopover,
            closeOnClick: false,
          },
        ]),
    ...(!options?.share
      ? []
      : [
          {
            title: 'Share',
            icon: IconLinearExport2,
            onClick: showPostPermissionDialog,
            closeOnClick: false,
          },
        ]),
    ...(!options?.download || !isPostDownloadable(post)
      ? []
      : [
          {
            title: 'Download',
            icon: IconLinearArrowDown2,
            onClick: () => {
              triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
                posts: [post],
                canDownloadSinglePost: true,
              });
            },
            closeOnClick: true,
          },
        ]),
    ...(!!options?.pin || !!options?.duplicate || !!options?.setCover
      ? [{ isDivider: true }]
      : []),
    ...(!options?.pin || !collectionId
      ? []
      : [
          isPostPinToCollection
            ? {
                title: 'Unpin from this Collection',
                icon: IconCustomPinOutline,
                onClick: () =>
                  onUnpinManyPostsFromCollection(collectionId, [post.id]),
                closeOnClick: true,
              }
            : {
                title: 'Pin to this Collection',
                icon: IconCustomPinOutline,
                onClick: () =>
                  onPinManyPostsToCollection(collectionId, [post.id]),
                closeOnClick: true,
              },
        ]),
    ...(!options?.duplicate
      ? []
      : [
          {
            title: 'Duplicate',
            icon: IconOutlineCopy,
            onClick: () => {
              onDuplicatePost({
                postId: post.id,
                collectionId,
              });
            },
            closeOnClick: true,
          },
        ]),
    ...(!options?.setCover || !canEditCollection || !collectionId
      ? []
      : [
          {
            title: `Set cover`,
            icon: IconOutlineEdit2,
            onClick: () => {
              onCollectionPreviewUpdate(collectionId, post.id);
              toast({
                message: 'Cover updated',
                type: 'success',
              });
            },
            color: theme.colors?.primary.black,
            closeOnClick: true,
          },
        ]),
    ...(!!options?.movePost ||
    !!options?.organize ||
    !!options?.share ||
    !!options?.download ||
    !!options?.pin ||
    !!options?.duplicate ||
    !!options?.setCover
      ? [{ isDivider: true }]
      : []),
    ...(!options?.removeFromCollection || !collectionId || !canEditCollection
      ? []
      : [
          {
            title: 'Remove from collection',
            icon: IconLinearSaveRemove,
            color: theme.colors?.primary.maroon,
            onClick: () => {
              onRemovePostFromCollection({
                postId: post.id,
                collectionId,
              });
            },
            closeOnClick: true,
          },
        ]),
    ...(!options?.delete
      ? []
      : [
          {
            title: 'Delete',
            icon: IconOutlineTrash,
            color: theme.colors?.primary.maroon,
            onClick: () =>
              openDeletePostConfirmationDialog({
                subtitle: `Once it removed, this ${
                  post.type === PostType.Note ? 'note' : 'post'
                } will no longer be accessible.`,
                onConfirm: () => {
                  onDeletePost(post.id);

                  // After deleting the post, navigate to next post if any else navigate back to bg location only if the user is viewing the post
                  if (context === 'post') {
                    if (nextPost) {
                      navigate(
                        {
                          pathname:
                            nextPost.type === PostType.Note
                              ? PlotRoutes.juiceboxNote({ id: nextPost.id })
                              : PlotRoutes.juice(nextPost.id),
                          search: params.toString(),
                        },
                        {
                          state: location.state,
                          replace: true,
                        },
                      );
                    } else if (locationState?.backgroundLocation) {
                      navigate(locationState.backgroundLocation);
                    }
                  }
                },
              }),
            disabled: !canDelete,
            closeOnClick: false,
          },
        ]),
  ];

  return (
    <>
      <ContextMenu
        renderButton={() =>
          hideMenuButton ? null : (
            <IconButton>
              <IconLinearMore
                style={{
                  color: theme.colors?.primary.black,
                  transform: `rotate(90deg)`,
                }}
              />
            </IconButton>
          )
        }
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            isDivider: option.isDivider,
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} color={option.color} />}
                </Box>
                <Typography variant="subhead-lg" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            width: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        renderExtraBottom={
          !canDelete
            ? () => {
                return (
                  <Box
                    sx={{
                      mt: 3,
                      py: 4,
                      mx: 3,
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 3,
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <IconOutlineInfoCircle
                      size={16}
                      style={{
                        color: theme.colors?.primary.maroon,
                        flexShrink: 0,
                      }}
                    />
                    <Typography variant="subhead-lg">
                      You do not have the permission to delete this post.
                    </Typography>
                  </Box>
                );
              }
            : undefined
        }
        disableRestoreFocus
        sx={{
          '& .MuiMenu-paper': {
            backdropFilter: 'blur(20px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
          },
          ...(rest.sx || {}),
        }}
        {...rest}
        // Context menu should always stay hidden when post bookmark popover is open
        {...(isPostBookmarkPopoverOpen
          ? { open: false }
          : // Otherwise, if renderButton is false -> open state would probably be controlled from outside
          // as `ContextMenu` is dependent on the button to work as anchorEl
          rest.renderButton === false
          ? { open: rest.open }
          : // If nothing is specified, open state should be controlled by `ContextMenu` itself
            {})}
      />
      {isPostBookmarkPopoverOpen && (
        <PostBookmarkPopover
          post={post}
          open
          onClose={closePostBookmarkPopover}
          {...rest}
        />
      )}
      {deletePostDialog}
      {renderMoveToAnotherPostModal()}
      {renderPostMovedSnackbar()}
      {renderPostPermissionDialogView()}
    </>
  );
};
