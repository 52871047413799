import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Badge, Box, IconButton, Typography } from '@mui/material';
import { BottomSheet } from 'components/common/BottomSheet/BottomSheet';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldSidebarLeft } from 'components/icons/components/bold/IconBoldSidebarLeft';
import { IconBoldSidebarRight } from 'components/icons/components/bold/IconBoldSidebarRight';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineInfoCircleAlt } from 'components/icons/components/outline/IconOutlineInfoCircleAlt';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { MOBILE_WIDTH_MD } from 'constants/mediaSizes';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { CUSTOM_COLLECTION } from 'features/collection';
import { PostManagerProvider } from 'features/juicebox/contexts';
import { getNoteColorFromColor } from 'features/note';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import { PermissionCreateRequestDialog } from 'features/permission';
import { PostContextMenu } from 'features/post';
import {
  POST_FRAGMENT_POST_PERMISSION_DAILOG_VIEW,
  PostPermissionDialogView,
} from 'features/post-permission';
import {
  POST_FRAGMENT_POST_DETAIL_SIDEBAR_VIEW,
  PostDetailSidebarView,
  SidebarTab,
} from 'features/post/views/detail';
import {
  CollectionPermission,
  PostFragmentJuicePostLayoutFragment,
  PostFragmentPostContextMenuFragmentDoc,
  PostType,
  UserFragmentAvatarGroupFragmentDoc,
  useGetCollectionForJuiceboxPostLayoutQuery,
  useGetPostCommentsUnreadCountQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { matchRoute } from 'utils/routes';

export const POST_FRAGMENT_JUICEBOX_POST_LAYOUT = gql`
  fragment PostFragmentJuicePostLayout on PostModel {
    id
    ...PostFragmentPostDetailSidebarView
    ...PostFragmentPostPermissionDialogView
    ...PostFragmentPostContextMenu
  }
  ${POST_FRAGMENT_POST_DETAIL_SIDEBAR_VIEW}
  ${POST_FRAGMENT_POST_PERMISSION_DAILOG_VIEW}
  ${PostFragmentPostContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionForJuiceboxPostLayout($collectionId: String!) {
    collection(id: $collectionId) {
      id
      name
      myPermissions
      creator {
        ...UserFragmentAvatarGroup
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

type JuiceboxPostLayoutProps = PropsWithChildren & {
  post?: PostFragmentJuicePostLayoutFragment;
};

export const JuiceboxPostLayout = ({
  children,
  post,
}: JuiceboxPostLayoutProps) => {
  const location = useLocation();
  const navigate = useGuardNavigate();

  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_MD);
  const { isMobileAppWebView } = useUserContext();

  const queryParams = new URLSearchParams(location.search);
  let collectionId = queryParams.get('collection');

  // If collectionId is one of CUSTOM_COLLECTIONS then set it to undefined
  if (Object.values(CUSTOM_COLLECTION).includes(collectionId as any)) {
    collectionId = null;
  }

  const {
    isOpen: isOpenCreateRequestDialog,
    onClose: onCloseCreateRequestDialog,
    onOpen: onOpenCreateRequestDialog,
  } = useDisclosure();

  const { disableCommands, enableCommands } = useCommandContext();

  const {
    setRenderTitle,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
    addRenderMenuExtraRight,
    removeRenderMenuExtraRight,
  } = useCustomHeaderContext();

  const {
    isOpen: isSidebarOpen,
    onOpen: openSidebar,
    onClose: closeSidebar,
  } = useDisclosure({
    defaultIsOpen: !isMobileView,
  });
  const [sidebarTab, setSidebarTab] = useState(SidebarTab.INFO);

  const { data: collectionData } = useGetCollectionForJuiceboxPostLayoutQuery({
    variables: {
      collectionId: collectionId || '',
    },
    skip: !collectionId || !post,
  });
  const collection = collectionData?.collection;

  const { data: unreadCommentData, updateQuery: updateUnreadCommentQuery } =
    useGetPostCommentsUnreadCountQuery({
      variables: {
        postId: post?.id || '',
      },
      skip: !post?.id,
    });
  const unreadCommentCount = unreadCommentData?.unreadPostComments || 0;

  // disable keyboard shortcuts when user doesn't have permission to upload post and show a dialog to request permission
  useEffect(() => {
    const commands = [
      COMMAND_TYPE.POST_PASTE_A_LINK,
      COMMAND_TYPE.POST_UPLOAD_FILES,
      COMMAND_TYPE.POST_WRITE_A_NOTE,
    ];

    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      const excludedInputTypes = ['input', 'textarea', 'select'];

      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        // If the rich text editor is open, do not call this.
        (document.activeElement.classList.contains('tiptap' || 'ProseMirror') ||
          excludedInputTypes.includes(
            document.activeElement.tagName.toLowerCase(),
          ));

      if (!isInputFocused) {
        if (
          ((event.metaKey || event.ctrlKey) &&
            (event.key === 'v' || event.key === 'u')) ||
          event.key === 'n'
        ) {
          disableCommands(commands);
          onOpenCreateRequestDialog();
        }
      }
    };

    if (
      collection &&
      !collection.myPermissions.includes(CollectionPermission.Update)
    ) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      enableCommands(commands);
    };
  }, [collection]); // eslint-disable-line

  const analytics = useAnalytics();

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const toggleSidebarWithTracking = (isSidebarOpen: boolean) => {
    clearTimeout(timeoutRef.current);

    if (!isSidebarOpen) {
      // This checks if the sidebar is currently closed, meaning the next action will open it.
      analytics.track(EventName.JuiceboxSidebarOpened);
    }

    // Reset side location when sidebar is open
    if (isSidebarOpen && (location.state as any)?.sideLocation?.length > 0) {
      navigate(location.pathname, {
        state: {
          ...(location.state as any),
          sideLocation: [],
        },
        replace: true,
      });
    }

    // Put these in a timeout so that the pages have time to render due to navigation (see the block above)
    // then we can open/close the sidebar. If we let these logic run immediately after the navigation,
    // the sidebar will not be able to open/close properly
    setTimeout(() => {
      if (isSidebarOpen) {
        openSidebar();
      } else {
        closeSidebar();
      }
    });
  };

  // Do this to trigger some logic that is dependent on the sidebar being open or not
  // E.g. calculating viewport width in ViewManager (annotation flow)
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [isSidebarOpen]);

  // This is used to show the unread comment count in the custom header
  // If sidebar is
  useEffect(() => {
    if (isSidebarOpen) {
      updateUnreadCommentQuery((prev) => ({ ...prev, unreadPostComments: 0 }));
    }
  }, [isSidebarOpen]); // eslint-disable-line

  useEffect(() => {
    if (
      post &&
      (matchRoute(location.pathname, '/juicebox/:id') ||
        matchRoute(location.pathname, '/juicebox/note/:id'))
    ) {
      setRenderTitle(() => (
        <Box
          component="button"
          onClick={() => navigate(-1)}
          display="flex"
          alignItems="center"
          gap={4}
          sx={{
            cursor: 'pointer',
            textAlign: 'left',
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <IconOutlineArrowLeft
            size={24}
            color={theme.colors?.primary.black}
            style={{ flexShrink: 0 }}
          />
          <Typography
            variant="headline-xs"
            color={theme.colors?.primary.black}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {post.title}
          </Typography>
        </Box>
      ));

      const renderPostContextMenu = () => (
        <PostContextMenu
          post={post}
          context="post"
          options={{
            organize: false,
            share: false,
            pin: false,
            setCover: false,
            duplicate: true,
            download: true,
            delete: true,
          }}
        />
      );
      addRenderMenuExtraRight(renderPostContextMenu, 0);

      const renderShareButton = () => (
        <Box mr={2}>
          <PostPermissionDialogView post={post} />
        </Box>
      );
      addRenderMenuExtraLeft(renderShareButton, 1);

      return () => {
        removeRenderMenuExtraLeft(renderShareButton, 1);
        removeRenderMenuExtraRight(renderPostContextMenu, 0);
      };
    }
  }, [location.pathname, post]); // eslint-disable-line

  useEffect(() => {
    if (
      post &&
      (matchRoute(location.pathname, '/juicebox/:id') ||
        matchRoute(location.pathname, '/juicebox/note/:id'))
    ) {
      const renderInfoButton = () => (
        <IconButton
          onClick={() => {
            if (isSidebarOpen && sidebarTab === SidebarTab.INFO) {
              closeSidebar();
            } else {
              setSidebarTab(SidebarTab.INFO);
              openSidebar();
            }
          }}
        >
          <IconOutlineInfoCircleAlt
            size={isMobileView ? 20 : 24}
            color={theme.colors?.primary.black}
          />
        </IconButton>
      );
      addRenderMenuExtraLeft(renderInfoButton, 2);

      const renderCommentButton = () => (
        <IconButton
          onClick={() => {
            if (isSidebarOpen && sidebarTab === SidebarTab.COMMENTS) {
              closeSidebar();
            } else {
              setSidebarTab(SidebarTab.COMMENTS);
              openSidebar();
            }
          }}
        >
          <Badge
            badgeContent={unreadCommentCount}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: theme.colors?.primary.maroon,
                color: theme.colors?.primary.white,
              },
            }}
          >
            <IconLinearMessage
              size={isMobileView ? 20 : 24}
              color={theme.colors?.primary.black}
            />
          </Badge>
        </IconButton>
      );
      addRenderMenuExtraLeft(renderCommentButton, 3);

      return () => {
        removeRenderMenuExtraLeft(renderInfoButton, 2);
        removeRenderMenuExtraLeft(renderCommentButton, 3);
      };
    }
  }, [location.pathname, post, unreadCommentCount, sidebarTab, isSidebarOpen]); // eslint-disable-line

  const themeColor = useMemo(() => {
    // TODO: currently only note have color picker
    // if note has color; use it else use random color for post
    if (post?.color && post.type === PostType.Note) {
      return getNoteColorFromColor(post.color);
    }

    // Get a random color from the set of predefine colors
    return NOTE_COLORS[Math.floor(Math.random() * NOTE_COLORS.length)];
  }, [post?.color]); // eslint-disable-line

  return (
    <PostManagerProvider>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          borderRadius: 0,
          border: 0,
          overflow: 'hidden',
          bgcolor: theme.colors?.utility[300],
          // note screen needs to be fullscreen without padding and borders when displayed on mobile apps
          // Be careful when changing this value, make sure the note view experience on mobile is still good
          pt: isMobileAppWebView ? 0 : 18,
          px: isMobileAppWebView ? 0 : 2,
          pb: isMobileAppWebView ? 0 : 2,
        }}
      >
        <Box display="flex" height="100%" width="100%" gap={2}>
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              // remove border when displayed on mobile apps
              borderRadius: isMobileAppWebView ? 0 : 6,
              color: themeColor?.textColor,
              bgcolor: themeColor?.color,
              maxWidth: '100%',
            }}
          >
            {children}
            {!isMobileView && (
              <IconButtonWithTooltip
                tooltip="Toggle sidebar"
                size="small"
                onClick={() => toggleSidebarWithTracking(!isSidebarOpen)}
                sx={{
                  color: 'inherit',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  m: 6,
                  p: 0,
                  transition: '0.2s all',
                  opacity: 0.4,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                {isSidebarOpen ? (
                  <IconBoldSidebarRight />
                ) : (
                  <IconBoldSidebarLeft />
                )}
              </IconButtonWithTooltip>
            )}
          </Box>

          {/* DO NOT render sidebar if users are creating new post (note) */}
          {!isMobileView ? (
            <Box
              sx={{
                width: isSidebarOpen ? '33.33%' : '0',
                minWidth: isSidebarOpen ? '333px' : '0',
                transition: 'all 0.2s',
                overflow: 'hidden',
              }}
            >
              <PostDetailSidebarView
                post={post}
                tab={sidebarTab}
                unreadCommentCount={unreadCommentData?.unreadPostComments || 0}
              />
            </Box>
          ) : (
            <BottomSheet
              onClose={closeSidebar}
              isOpen={isSidebarOpen}
              showCloseButton
            >
              <PostDetailSidebarView
                post={post}
                tab={sidebarTab}
                unreadCommentCount={unreadCommentData?.unreadPostComments || 0}
              />
            </BottomSheet>
          )}
        </Box>
        {collection && (
          <PermissionCreateRequestDialog
            user={collection.creator}
            title={collection.name}
            headingText={
              <Typography
                variant="headline-xl"
                fontSize={28}
                fontWeight={500}
                letterSpacing="-1.12px"
              >
                Oops! You need full access to upload posts to ‘{collection.name}
                ’
              </Typography>
            }
            isOpen={isOpenCreateRequestDialog}
            onClose={() => {
              onCloseCreateRequestDialog();
            }}
            collectionId={collection.id}
          />
        )}
      </Box>
    </PostManagerProvider>
  );
};
