import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { PostContextMenu } from 'features/post/components';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigatePostSearchParams';
import {
  PostAnnotationFragmentAnnotationFragmentDoc,
  PostFragmentPostContextMenuFragmentDoc,
  PostFragmentPostDetailContentCollaborationFragmentDoc,
  PostFragmentPostDetailContentViewFragment,
  UrlMetadataFragmentIframelyFragmentDoc,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { PostDetailContentCollaboration } from './PostDetailContentCollaboration';
import { PostDetailContentRenderer } from './PostDetailContentRenderer';

export const POST_FRAGMENT_POST_DETAIL_CONTENT_VIEW = gql`
  fragment PostFragmentPostDetailContentView on PostModel {
    id
    createdAt
    urlMetadata {
      id
      ...URLMetadataFragmentIframely
    }
    annotations {
      id
      ...PostAnnotationFragmentAnnotation
    }
    ...PostFragmentPostDetailContentCollaboration
    ...PostFragmentPostContextMenu
  }
  ${UrlMetadataFragmentIframelyFragmentDoc}
  ${PostAnnotationFragmentAnnotationFragmentDoc}
  ${PostFragmentPostDetailContentCollaborationFragmentDoc}
  ${PostFragmentPostContextMenuFragmentDoc}
`;

export type PostDetailContentViewProps = {
  post: PostFragmentPostDetailContentViewFragment;

  // TODO: This is a temporary prop to disable next and prev navigation in post preview window
  // We'll remove this once we have handled passing in task id into this view as filters for prev/next navigation.
  allowNextAndPrevNavigation?: boolean;
};

export const PostDetailContentView = (props: PostDetailContentViewProps) => {
  const { post, allowNextAndPrevNavigation = true } = props;

  const isMobileView = useMediaQueryMobile();

  const { renderPrevBtn, renderNextBtn } = useNavigatePostSearchParams();

  return (
    <>
      <PostDetailContentCollaboration post={post} />

      <RightClickContextMenuWrapper
        key={props.post.id}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
        renderMenu={(props) => (
          <PostContextMenu
            post={post}
            renderButton={false}
            {...props}
            options={{
              download: true,
              duplicate: true,
              delete: true,
            }}
          />
        )}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: isMobileView ? 'calc(100% - 50px)' : 'calc(100% - 144px)',
            m: 'auto',
            pt: isMobileView ? 22 : 12,
            pb: isMobileView ? 18 : 12,
            maxHeight: isMobileView ? 'calc(100vh - 200px)' : '100vh',
            position: 'absolute',
            inset: 0,
          }}
        >
          <PostDetailContentRenderer post={post} />
        </Box>
        {allowNextAndPrevNavigation ? (
          <>
            {renderPrevBtn()}
            {renderNextBtn()}
          </>
        ) : null}
      </RightClickContextMenuWrapper>
    </>
  );
};
