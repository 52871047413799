import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import { MasonrySkeleton } from 'components/common/Skeleton';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { customCollectionPostFilterTypes } from 'features/collection';
import { PostMasonryCardView } from 'features/post';
import { PostFragmentPostCommandFragment } from 'graphql/generated';
import { usePostQueryForCustomCollection } from 'pages/collections/detail/hooks/usePostQueryForCustomCollection';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

type CustomCollectionPostMasonrySectionProps = {
  collectionId: string;
  selectedPostIds: string[];
  onPostClick: (post: PostFragmentPostCommandFragment) => void;
};

export const CustomCollectionPostMasonrySection = (
  props: CustomCollectionPostMasonrySectionProps,
) => {
  const { collectionId, selectedPostIds, onPostClick } = props;

  // NOTE: Reusing query from Collection page
  const { data, loading, fetchMore } = usePostQueryForCustomCollection({
    filters: {
      filterType: customCollectionPostFilterTypes[collectionId],
    },
  });
  const posts = data?.postsSmartSearch.data.map(({ item }) => item) || [];
  const hasNextPage = data?.postsSmartSearch.pageInfo.hasNextPage || false;

  if (loading) {
    return <MasonrySkeleton />;
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      initialLoad={false}
      loadMore={fetchMore}
      threshold={1024}
      useWindow={false}
      getScrollParent={() =>
        document.querySelector(
          '.collection-cjb-window-view-scrollable-container',
        )
      }
    >
      <Box sx={{ mr: -4 }}>
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4}>
          {posts.map((post) => {
            const selected = selectedPostIds.includes(post.id);

            return (
              <Box
                key={post.id}
                sx={{
                  position: 'relative',
                  borderRadius: 3,
                  ...(selected && {
                    color: theme.colors?.primary.black,
                    outline: selected
                      ? `3px solid ${theme.colors?.primary.black}`
                      : 'none',
                    outlineOffset: 3,
                    backgroundColor: theme.colors?.primary.white,
                  }),
                }}
              >
                <PostMasonryCardView
                  post={post}
                  componentsProps={{
                    rightClickContextMenu: false,
                  }}
                  onClick={() => onPostClick(post)}
                />
                {selected && (
                  <IconBoldTickCircle
                    size={16}
                    style={{ position: 'absolute', top: 0, left: 0, margin: 8 }}
                  />
                )}
              </Box>
            );
          })}
        </Masonry>
      </Box>
    </InfiniteScroll>
  );
};
