import { gql } from '@apollo/client';
import { Box, Skeleton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearLock1 } from 'components/icons/components/linear/IconLinearLock1';
import { PostPreview } from 'features/post';
import {
  PermittedCollectionFragmentOrganizationMembersPermittedCollectionListItemFragment,
  PostFragmentPostPreviewFragmentDoc,
  PostType,
  useGetPermittedPostsForOrganizationMembersPermittedCollectionListItemQuery,
} from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const PERMITTED_COLLECTION_FRAGMENT_ORGANIZATION_MEMBERS_PERMITTED_COLLECTION_LIST_ITEM = gql`
  fragment PermittedCollectionFragmentOrganizationMembersPermittedCollectionListItem on PermittedCollectionModel {
    id
    permissionLevel
    collection {
      id
      name
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetPermittedPostsForOrganizationMembersPermittedCollectionListItem(
    $filters: PermittedPostFilters!
  ) {
    permittedPosts(filters: $filters) {
      id
      permissionLevel
      post {
        id
        ...PostFragmentPostPreview
      }
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type PermittedCollectionListItemProps = {
  userId: string;
  permittedCollection: PermittedCollectionFragmentOrganizationMembersPermittedCollectionListItemFragment;
};

export const PermittedCollectionListItem = (
  props: PermittedCollectionListItemProps,
) => {
  const { userId, permittedCollection } = props;

  const { data, loading } =
    useGetPermittedPostsForOrganizationMembersPermittedCollectionListItemQuery({
      variables: {
        filters: {
          userId,
          collectionId: permittedCollection.id,
        },
      },
    });
  const permittedPosts = data?.permittedPosts || [];
  const hasNoPosts = !loading && permittedPosts.length === 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="headline-xxs"
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          {!permittedCollection.collection && <IconLinearLock1 size={14} />}
          {permittedCollection.collection ? (
            <Link
              to={PlotRoutes.collection(permittedCollection.collection.id)}
              target="_blank"
            >
              {permittedCollection.collection.name}
            </Link>
          ) : (
            'No Access'
          )}
          <Box component="span" color={theme.colors?.utility[500]}>
            ({permittedCollection.permissionLevel || permittedPosts.length})
          </Box>
        </Typography>
      </Box>
      {!hasNoPosts && (
        <Box sx={{ display: 'flex', gap: 2.5 }}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={36}
              height={36}
              sx={{ borderRadius: 1 }}
            />
          ) : (
            permittedPosts.map((p) => (
              <Box
                key={p.id}
                sx={{
                  width: 36,
                  height: 36,
                  borderRadius: 1,
                  overflow: 'hidden',
                }}
              >
                {p.post ? (
                  <Link
                    to={
                      p.post.type === PostType.Note
                        ? PlotRoutes.juiceboxNote({ id: p.post.id })
                        : PlotRoutes.juice(p.post.id)
                    }
                    target="_blank"
                  >
                    <PostPreview variant="compact" post={p.post} />
                  </Link>
                ) : (
                  // TODO: Extract this into a component
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: theme.colors?.primary.parchment,
                    }}
                  >
                    <IconLinearLock1 size={16} />
                  </Box>
                )}
              </Box>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export const PermittedCollectionListItemSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Skeleton
        variant="rectangular"
        width={100}
        height={24}
        sx={{ borderRadius: 1 }}
      />
      <Box sx={{ display: 'flex', gap: 2.5 }}>
        <Skeleton
          variant="rectangular"
          width={36}
          height={36}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          width={36}
          height={36}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          width={36}
          height={36}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          width={36}
          height={36}
          sx={{ borderRadius: 1 }}
        />
      </Box>
    </Box>
  );
};
