import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Snackbar, Typography } from '@mui/material';
import { CollectionMultiPostPreview } from 'features/collection';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionFragmentCollectionMultiPostPreviewFragment,
  GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
  GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
  usePinCollectionToRootMutation,
  usePinManyCollectionsToParentMutation,
  usePinManyPostsToCollectionMutation,
  useUnpinCollectionFromRootMutation,
  useUnpinManyCollectionsFromParentMutation,
  useUnpinManyPostsFromCollectionMutation,
} from 'graphql/generated';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation PinManyCollectionsToParent($data: PinManyCollectionsToParentInput!) {
    pinManyCollectionsToParent(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UnpinManyCollectionsFromParent(
    $data: UnpinManyCollectionsFromParentInput!
  ) {
    unpinManyCollectionsFromParent(data: $data) {
      success
      message
      deleted {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation PinCollectionToRoot($data: PinCollectionInput!) {
    pinCollectionToRoot(data: $data) {
      id
      collection {
        ...CollectionFragmentCollectionCardView
      }
    }
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UnpinCollectionFromRoot($data: UnpinCollectionInput!) {
    unpinCollectionFromRoot(data: $data) {
      success
      message
      deleted {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation PinManyPostsToCollection($data: PinManyPostsToCollectionInput!) {
    pinManyPostsToCollection(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UnpinManyPostsFromCollection(
    $data: UnpinManyPostsFromCollectionInput!
  ) {
    unpinManyPostsFromCollection(data: $data) {
      success
      message
      deleted {
        id
      }
    }
  }
`;

export const usePinnedEntityMutations = () => {
  const currentCollectionId = useCollectionIdFromParams().collectionId;

  const [pinManyCollectionsToParent] = usePinManyCollectionsToParentMutation();
  const [unpinManyCollectionsFromParent] =
    useUnpinManyCollectionsFromParentMutation();

  const [pinCollectionToRoot] = usePinCollectionToRootMutation();
  const [unpinCollectionFromRoot] = useUnpinCollectionFromRootMutation();

  const [pinManyPostsToCollection] = usePinManyPostsToCollectionMutation();
  const [unpinManyPostsFromCollection] =
    useUnpinManyPostsFromCollectionMutation();

  const onPinManyCollectionsToParent = async (collectionIds: string[]) => {
    return pinManyCollectionsToParent({
      variables: {
        data: {
          collectionIds,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache, data) => {
        if (currentCollectionId) {
          modifyObject(cache, currentCollectionId, 'CollectionModel', {
            pinnedEntities: (existingFieldValues = []) => {
              return [
                ...existingFieldValues,
                ...(data.data?.pinManyCollectionsToParent || []),
              ];
            },
          });
        }

        collectionIds.forEach((collectionId) => {
          modifyObject(cache, collectionId, 'CollectionModel', {
            isPinnedToParent: () => true,
          });
        });
      },
    });
  };

  const onUnpinManyCollectionsFromParent = async (collectionIds: string[]) => {
    return unpinManyCollectionsFromParent({
      variables: {
        data: {
          collectionIds,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache, data) => {
        data.data?.unpinManyCollectionsFromParent?.deleted?.forEach(
          (pinnedEntityId) => {
            evictObject(cache, pinnedEntityId.id, 'PinnedEntityModel');
          },
        );

        collectionIds.forEach((collectionId) => {
          modifyObject(cache, collectionId, 'CollectionModel', {
            isPinnedToParent: () => false,
          });
        });
      },
    });
  };

  const {
    isOpen: onPinCollectionToRootOpen,
    onOpen: onPinCollectionToRootOnOpen,
    onClose: onPinCollectionToRootOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (onPinCollectionToRootOpen) {
      setTimeout(() => {
        onPinCollectionToRootOnClose();
      }, 4_000);
    }
  }, [onPinCollectionToRootOpen]); // eslint-disable-line

  const onPinCollectionToRoot = async (
    collectionId: string,
    context?: 'parent' | 'root',
  ) => {
    return pinCollectionToRoot({
      variables: {
        data: {
          collectionId,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache) => {
        modifyObject(cache, collectionId, 'CollectionModel', {
          isPinnedToRoot: () => true,
        });
      },
      onCompleted: () => {
        if (context === 'parent') {
          onPinCollectionToRootOnOpen();
        }
      },
    });
  };

  const renderCollectionPinnedToRootFeedback = (
    collection?: CollectionFragmentCollectionMultiPostPreviewFragment,
  ) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            minWidth: `${theme.spacing(collection ? 80 : 54)} !important`,
            justifyContent: collection ? 'flex-start' : 'center',
            bgcolor: theme.colors?.utility['orange-1'],
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(6),
          },
        }}
        open={onPinCollectionToRootOpen}
        message={
          <Link to={PlotRoutes.juicebox()}>
            <Box
              display="flex"
              gap={4}
              alignItems="center"
              justifyContent="center"
            >
              {collection && (
                <Box
                  sx={{
                    width: theme.spacing(15),
                    height: theme.spacing(15),
                    mt: 4,
                    ml: 4,
                  }}
                >
                  <CollectionMultiPostPreview
                    sx={{ width: theme.spacing(15), height: theme.spacing(15) }}
                    collection={collection}
                    variant="card-stack-square"
                  />
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.utility['orange-4']}
                >
                  Collection Pinned
                </Typography>

                <Typography
                  variant="headline-sm"
                  color={theme.colors?.primary.black}
                >
                  View on Juicebox
                </Typography>
              </Box>
            </Box>
          </Link>
        }
      />
    );
  };

  const onUnpinCollectionFromRoot = async (collectionId: string) => {
    return unpinCollectionFromRoot({
      variables: {
        data: {
          collectionId,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache, data) => {
        modifyObject(cache, collectionId, 'CollectionModel', {
          isPinnedToRoot: () => false,
        });

        evictObject(
          cache,
          data.data?.unpinCollectionFromRoot?.deleted?.[0]?.id ?? '',
          'PinnedEntityModel',
        );
      },
    });
  };

  const onPinManyPostsToCollection = async (
    collectionId: string,
    postIds: string[],
  ) => {
    return pinManyPostsToCollection({
      variables: {
        data: {
          collectionId,
          postIds,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache, data) => {
        if (currentCollectionId) {
          modifyObject(cache, currentCollectionId, 'CollectionModel', {
            pinnedEntities: (existingFieldValues = []) => {
              return [
                ...existingFieldValues,
                ...(data.data?.pinManyPostsToCollection || []),
              ];
            },
          });
        }

        postIds.forEach((postId) => {
          modifyObject(cache, postId, 'PostModel', {
            pinnedToCollections: (existingFieldValues = []) => {
              return [
                ...existingFieldValues,
                ...(data.data?.pinManyPostsToCollection || []),
              ];
            },
          });
        });
      },
    });
  };

  const onUnpinManyPostsFromCollection = async (
    collectionId: string,
    postIds: string[],
  ) => {
    return unpinManyPostsFromCollection({
      variables: {
        data: {
          postIds,
          collectionId,
        },
      },
      refetchQueries: [
        GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
        GetPinnedEntitiesToParentForListViewPinnedCollectionsAndPostsSectionDocument,
      ],
      update: (cache, data) => {
        data.data?.unpinManyPostsFromCollection.deleted?.forEach(
          (pinnedEntity) => {
            evictObject(cache, pinnedEntity.id, 'PinnedEntityModel');
          },
        );
      },
    });
  };

  return {
    onPinManyCollectionsToParent,
    onUnpinManyCollectionsFromParent,

    onPinCollectionToRoot,
    onUnpinCollectionFromRoot,

    onPinManyPostsToCollection,
    onUnpinManyPostsFromCollection,

    renderCollectionPinnedToRootFeedback,
  };
};
