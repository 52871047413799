import { createContext } from '@dwarvesf/react-utils';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  CollectionFragmentSelectModeFragment,
  PostFragmentSelectModeFragment,
} from 'graphql/generated';
import { ReactNode, useEffect, useState } from 'react';
import { useSelectMode } from '../../hooks/useSelectMode';

export type JuiceboxSelectContextValues = {
  /**
   * Target collections that would be counted in select mode.
   * These will be used for internal logic of useSelectMode hook.
   *
   * FIXME: Type any. Rn this is most likely a fragment from collection dnd flow (collection dnd card).
   * For now we keep it as any because a lot of the hooks are incorrectly typed yet dependent on this.
   */
  collections: any[];
  addCollections: (collections: CollectionFragmentSelectModeFragment[]) => void;

  /**
   * Target posts that would be counted in select mode.
   * These will be used for internal logic of useSelectMode hook.
   *
   * FIXME: Type any. Rn this is most likely a fragment from post dnd flow (post dnd card).
   * For now we keep it as any because a lot of the hooks are incorrectly typed yet dependent on this.
   */
  posts: any[];
  addPosts: (posts: PostFragmentSelectModeFragment[]) => void;

  isSelectModeDisabled: boolean;
  enableSelectMode: () => void;
  disableSelectMode: () => void;
} & ReturnType<typeof useSelectMode>;

const [Provider, useJuiceboxSelectContext] =
  createContext<JuiceboxSelectContextValues>();

export const JuiceboxSelectContextProvider = (props: {
  children: ReactNode;
}) => {
  const { children } = props;

  const { collectionId = '' } = useCollectionIdFromParams();

  const [collections, setCollections] = useState<
    CollectionFragmentSelectModeFragment[]
  >([]);
  const addCollections = (
    newCollections: CollectionFragmentSelectModeFragment[],
  ) => {
    setCollections(
      [...collections, ...newCollections].filter(
        (collection, index, self) =>
          self.findIndex((c) => c.id === collection.id) === index,
      ),
    );
  };

  const [posts, setPosts] = useState<PostFragmentSelectModeFragment[]>([]);
  const addPosts = (newPosts: PostFragmentSelectModeFragment[]) => {
    setPosts(
      [...posts, ...newPosts].filter(
        (post, index, self) =>
          self.findIndex((p) => p.id === post.id) === index,
      ),
    );
  };

  const [disabled, setDisabled] = useState(false);
  const enableSelectMode = () => setDisabled(false);
  const disableSelectMode = () => setDisabled(true);

  const useSelectModeValues = useSelectMode({
    collections,
    posts,
    disabled,
  });

  useEffect(() => {
    // Reset collections and posts when collectionId changes
    useSelectModeValues.exitSelectMode();
    setCollections([]);
    setPosts([]);
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider
      value={{
        collections,
        addCollections,
        posts,
        addPosts,
        isSelectModeDisabled: disabled,
        enableSelectMode,
        disableSelectMode,
        ...useSelectModeValues,
      }}
    >
      {children}
    </Provider>
  );
};

export { useJuiceboxSelectContext };
