import { Box, Radio, SxProps, Typography } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ContextMenu } from 'components/common/ContextMenu';
import { DateRangePicker } from 'components/common/DatePicker';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { JuiceboxFilterButton } from 'features/juicebox/components';
import {
  JuiceboxDateType,
  JuiceboxFilter,
  JuiceboxFilterType,
} from 'features/juicebox/hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export enum DateFilterType {
  Today = 'Today',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  ThisYear = 'ThisYear',
  LastYear = 'LastYear',
  CustomRange = 'CustomRange',
}

interface Props {
  selectedDate: JuiceboxFilter | undefined;
  setSelectedDate: (input?: JuiceboxFilter) => void;
  componentsProps?: {
    filterButton?: {
      sx?: SxProps;
    };
  };
}

export const JuiceboxDateSelectButtonFilter = ({
  selectedDate,
  setSelectedDate,
  componentsProps = {},
}: Props) => {
  const [selectedDateFilter, setSelectedDateFilter] =
    useState<DateFilterType>();
  const [dates, setDates] = useState<DateRange<moment.Moment>>([null, null]);
  const [showCalendar, setShowCalendar] = useState(false);

  const dateFilters = [
    { value: DateFilterType.Today, text: 'Today' },
    { value: DateFilterType.Last7Days, text: 'Last 7 days' },
    { value: DateFilterType.Last30Days, text: 'Last 30 days' },
    {
      value: DateFilterType.ThisYear,
      text: `This year ${new Date().getFullYear()}`,
    },
    {
      value: DateFilterType.LastYear,
      text: `Last year ${new Date().getFullYear() - 1}`,
    },
    {
      value: DateFilterType.CustomRange,
      text: 'Custom range',
      shouldShowCustomRange: true,
    },
  ];

  // Reset selected filter when date range is cleared
  useEffect(() => {
    if (!selectedDate) {
      setSelectedDateFilter(undefined);
      setDates([null, null]);
    } else {
      setSelectedDateFilter(selectedDate.value as DateFilterType);
    }
  }, [selectedDate]);

  // Update selected date filter when date range is changed
  useEffect(() => {
    if (selectedDateFilter) {
      const initalValue = {
        filterType: JuiceboxFilterType.Date,
        value: selectedDateFilter,
      };

      if (selectedDateFilter !== DateFilterType.CustomRange) {
        setSelectedDate({
          ...initalValue,
          label:
            dateFilters.find(
              (dateFilter) => dateFilter.value === selectedDateFilter,
            )?.text || '',
        });
      }
      if (
        selectedDateFilter === DateFilterType.CustomRange &&
        dates[0] &&
        dates[1]
      ) {
        setSelectedDate({
          ...initalValue,
          label: `${moment(dates[0]).format('DD MMM')} - ${moment(
            dates[1],
          ).format('DD MMM')}`,
          item: {
            startDate: dates[0]?.toDate(),
            endDate: dates[1]?.toDate(),
          } as JuiceboxDateType,
        });
      }
    } else {
      setSelectedDate(undefined);
      setSelectedDateFilter(undefined);
    }
  }, [JSON.stringify({ selectedDateFilter, dates })]);

  return (
    <ContextMenu
      renderButton={() => (
        <JuiceboxFilterButton
          text="Date modified"
          Icon={IconOutlineCalendar1}
          sx={componentsProps.filterButton?.sx}
        />
      )}
      PaperProps={{
        style: {
          minWidth: 266,
          borderRadius: theme.spacing(3),
          boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
        },
      }}
      options={dateFilters.map((dateFilter) => ({
        sx: {
          borderRadius: `${theme.spacing(3)} !important`,
          padding: theme.spacing(3, 4),
          ':hover': {
            backgroundColor: `rgba(35, 6, 3, 0.1)`,
          },
        },
        onClick: () => {
          if (!showCalendar) {
            setSelectedDateFilter((prevselectedDateFilter) => {
              return prevselectedDateFilter === dateFilter.value
                ? undefined
                : dateFilter.value;
            });
          }
        },

        closeOnClick: false,
        renderOption: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              width: '100%',
            }}
            onMouseEnter={() => {
              if (dateFilter.shouldShowCustomRange) {
                setShowCalendar(true);
              } else {
                setShowCalendar(false);
              }
            }}
          >
            <Radio
              checked={selectedDateFilter === dateFilter.value}
              sx={{
                padding: 0,
                '& .MuiSvgIcon-root': {
                  width: 16,
                  height: 16,
                  marginRight: `0 !important`,
                  color: `${theme.colors?.utility[900]} !important`,
                },
              }}
            />
            <Typography
              variant="subhead-lg"
              color={theme.colors?.primary.black}
            >
              {dateFilter.text}
            </Typography>
            {dateFilter.shouldShowCustomRange && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <IconLinearArrowRight1
                  size={16}
                  color={theme.colors?.primary.black}
                />
              </Box>
            )}

            <DateRangePicker
              calendars={1}
              open={
                showCalendar && dateFilter.value === DateFilterType.CustomRange
              }
              onClose={() => {
                setShowCalendar(false);
              }}
              value={dates}
              onChange={(dates) => {
                setSelectedDateFilter(DateFilterType.CustomRange);
                setDates(dates);
              }}
              sx={{ '& .MuiTypography-root': { display: 'none' } }}
              slots={{
                // eslint-disable-next-line
                textField: () => null,
              }}
              slotProps={{
                actionBar: {
                  actions: [],
                },
              }}
            />
          </Box>
        ),
      }))}
    />
  );
};
