/**
 * This is a temporary hook where we handle user invitation through a link
 */

import { gql } from '@apollo/client';
import {
  MeFragmentUserQueriesFragment,
  useJoinCollectionThroughLinkForUseInvitationThroughLinkMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InvitationThroughLinkInvitedToCollectionView } from '../../views';

const SESSION_STORAGE_REFERRAL_SOURCE = 'referralSource';
const SESSION_STORAGE_INVITE_TO = 'inviteTo';

// eslint-disable-next-line
gql`
  mutation JoinCollectionThroughLinkForUseInvitationThroughLink(
    $data: JoinCollectionThroughLinkInput!
  ) {
    joinCollectionThroughLink(data: $data) {
      success
      message
    }
  }
`;

export type UseInvitationThroughLinkProps = {
  user?: MeFragmentUserQueriesFragment | null;
};

export const useInvitationThroughLink = (
  props: UseInvitationThroughLinkProps,
) => {
  const { user } = props;

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [joinCollectionThroughLink] =
    useJoinCollectionThroughLinkForUseInvitationThroughLinkMutation();

  const [referralSource, setReferralSource] = useState(
    params.get('referralSource') ||
      window.sessionStorage.getItem(SESSION_STORAGE_REFERRAL_SOURCE) ||
      '',
  );

  const [inviteTo, setInviteTo] = useState(
    params.get('inviteTo') ||
      window.sessionStorage.getItem(SESSION_STORAGE_INVITE_TO) ||
      '',
  );
  const [inviteToEntityType, inviteToEntityId] = inviteTo.split(':');
  const [shouldRenderInvitedView, setShouldRenderInvitedView] = useState(false);

  useEffect(() => {
    const newParams = new URLSearchParams(params);

    if (params.get('referralSource')) {
      setReferralSource(params.get('referralSource') || '');
      window.sessionStorage.setItem(
        SESSION_STORAGE_REFERRAL_SOURCE,
        params.get('referralSource') || '',
      );
      newParams.delete('referralSource');
    }

    if (params.get('inviteTo')) {
      setInviteTo(params.get('inviteTo') || '');
      window.sessionStorage.setItem(
        SESSION_STORAGE_INVITE_TO,
        params.get('inviteTo') || '',
      );
      newParams.delete('inviteTo');
    }

    navigate({ search: newParams.toString() });
  }, [params]); // eslint-disable-line

  const onCloseInvitedView = () => {
    setShouldRenderInvitedView(false);
    setInviteTo('');
    window.sessionStorage.removeItem(SESSION_STORAGE_INVITE_TO);
  };

  // Invite user to certain entities if
  // 1. User is logged in
  // 2. params.inviteTo is provided
  useEffect(() => {
    (async () => {
      if (user && inviteToEntityType && inviteToEntityId) {
        switch (inviteToEntityType) {
          case 'collection': {
            // Invite user to collection
            joinCollectionThroughLink({
              variables: {
                data: {
                  collectionId: inviteToEntityId,
                },
              },
            });

            break;
          }
          default: {
            break;
          }
        }

        setShouldRenderInvitedView(true);
      }
    })();
  }, [user, inviteToEntityId, inviteToEntityType]); // eslint-disable-line

  const renderInvitedView = () => {
    if (!shouldRenderInvitedView) {
      return null;
    }

    switch (inviteToEntityType) {
      case 'collection': {
        return (
          <InvitationThroughLinkInvitedToCollectionView
            collectionId={inviteToEntityId}
            onClose={onCloseInvitedView}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return {
    referralSource,
    inviteTo,
    renderInvitedView,
  };
};
