import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { OrgRoleSelector } from 'features/organizationMembers/components/OrgRoleSelector';
import { useOrgMemberManagement } from 'features/organizationMembers/hooks';
import { ManageUsersInput } from 'features/permission/components/manageUsersInput/ManageUsersInput';
import {
  InternalOrganizationRole,
  OrganizationPermission,
  UserFragmentAddUsersToOrgViewFragment,
} from 'graphql/generated';
import { useOrganizationPermissions } from 'hooks/permissions/useOrganizationPermissions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getSuggestedEmails, isWorkDomain } from 'utils/orgDomain';
import { getFullName } from 'utils/users';
import { emailValidation } from 'utils/validations';
import { ManageUsersList, NEW_USER_ID } from './sections/manageUsersList';
import { MemberInviteConfirmation } from './sections/memberInviteConfirmation';
import { MemberUserRoleSelection } from './sections/memberUserRoleSelection';
import { SocialListeningUsersInvited } from './sections/socialListeningUsersInvited';
import { Props } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation InviteUsersToCurrentOrgForAddUsersToOrgView(
    $data: InviteUsersToCurrentOrgInput!
  ) {
    inviteUsersToCurrentOrg(data: $data) {
      id
    }
  }
`;

const getTempUser = (email: string) => {
  return { id: NEW_USER_ID, firstName: '', lastName: '', email, title: '' };
};

enum MemberInviteState {
  EmailSelection,
  RoleSelection,
  MemberAddedToSocialListening,
  ConfirmPaidMembersInvite,
}

export const AddUsersToOrgView = ({ existingUsers, onAfterInvite }: Props) => {
  const [hasError, setHasError] = useState(false);
  const { user, orgBilling } = useUserContext();
  const isSocialListeningEnabled = orgBilling?.socialListeningEnabled;

  const { inviteMembers, inviteEnterpriseOrgMembers } =
    useOrgMemberManagement();

  const [alreadyExistMember, setAlreadyExistMember] =
    useState<UserFragmentAddUsersToOrgViewFragment | null>(null);
  const [searchStr, setSearchStr] = useState('');
  const [selectedRole, setSelectedRole] = useState(
    isSocialListeningEnabled &&
      user?.role === InternalOrganizationRole.SocialListeningUser
      ? InternalOrganizationRole.SocialListeningUser
      : InternalOrganizationRole.User,
  );

  const [userRoleData, setUserRoleData] = useState<{
    [email: string]: InternalOrganizationRole;
  }>({});

  const [currentView, setCurrentView] = useState<MemberInviteState>(
    MemberInviteState.EmailSelection,
  );

  const onRoleChange = (email: string, role: InternalOrganizationRole) => {
    setUserRoleData({ ...userRoleData, [email]: role });
  };

  const permissions = useOrganizationPermissions();
  const canUpdateRole = permissions.includes(
    OrganizationPermission.MemberManagement,
  );

  useEffect(() => {
    setHasError(false);
  }, [searchStr]);

  // This will maintain the actual actively selected emails by the user, and is the list that will ben sent to the server
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  useEffect(() => {
    const newUserRoleData: { [email: string]: InternalOrganizationRole } = {};
    selectedEmails.forEach((email) => {
      newUserRoleData[email] =
        userRoleData[email] || InternalOrganizationRole.User;
    });
    setUserRoleData(newUserRoleData);
  }, [selectedEmails]); // eslint-disable-line

  // Added users will allow to re-add users without typing them again using the checkbox after removing from the list
  const [tempAddedUsers, setTempAddedUsers] = useState<
    UserFragmentAddUsersToOrgViewFragment[]
  >([]);

  const addUser = (email: string) => {
    if (!tempAddedUsers.find((x) => x.email === email)) {
      setTempAddedUsers([
        ...tempAddedUsers,
        { id: NEW_USER_ID, firstName: '', lastName: '', email },
      ]);
    }
    setSelectedEmails([...selectedEmails, email]);
  };

  const removeUser = (email: string) => {
    setUserRoleData((prev) => {
      delete prev[email];
      return prev;
    });
    setSelectedEmails(selectedEmails.filter((x) => x !== email));
  };

  const allUsers = searchStr
    ? // Convert the search string to lowercase to avoid case sensitivity
      getSuggestedEmails(
        user?.organization.domain || '',
        searchStr.toLowerCase(),
      ).map(getTempUser)
    : [...tempAddedUsers, ...existingUsers]
        .filter((x) => x.email !== user?.email)
        .slice(0, 5);

  const validateAndAddEmail = (emailToAdd: string) => {
    if (emailToAdd && emailToAdd.trim()) {
      const isEmailValid = emailValidation.isValidSync(emailToAdd);
      setHasError(!isEmailValid);

      if (isEmailValid) {
        const newEmailDomain = emailToAdd.trim().split('@')[1];
        const currentUserDomain = user!.email.trim().split('@')[1];

        if (
          isWorkDomain(currentUserDomain) &&
          newEmailDomain !== currentUserDomain
        ) {
          setHasError(true);
          return;
        }

        if (!isWorkDomain(currentUserDomain) && isWorkDomain(newEmailDomain)) {
          setHasError(true);
          return;
        }

        const existingUser = existingUsers.find((x) => x.email === emailToAdd);
        if (existingUser) {
          setAlreadyExistMember(existingUser);
          return;
        }

        setAlreadyExistMember(null);
        addUser(emailToAdd.trim());
        setSearchStr('');

        setUserRoleData({
          ...userRoleData,
          [emailToAdd]: InternalOrganizationRole.User,
        });
      }
    }
  };

  const onInviteMembers = async () => {
    if (!isSocialListeningEnabled) {
      await inviteMembers(selectedEmails, selectedRole);
      onAfterInvite();
      return;
    }

    if (user?.role === InternalOrganizationRole.SocialListeningUser) {
      // invite all users as social listening users since the current user is a social listening user
      await inviteEnterpriseOrgMembers(
        selectedEmails.map((email) => ({
          email,
          role: InternalOrganizationRole.SocialListeningUser,
        })),
      );
      onAfterInvite();
      return;
    }

    if (selectedRole === InternalOrganizationRole.Admin) {
      setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);

      // TODO: move this to the onConfirm callback of the confirmation dialog
      await inviteEnterpriseOrgMembers(
        selectedEmails.map((email) => ({
          email,
          role: InternalOrganizationRole.Admin,
        })),
      );
      onAfterInvite();
      return;
    }

    if (currentView === MemberInviteState.EmailSelection) {
      setCurrentView(MemberInviteState.RoleSelection);
    } else if (currentView === MemberInviteState.RoleSelection) {
      const rolesToInvite = Object.values(userRoleData);
      if (
        rolesToInvite.every((role) => role === InternalOrganizationRole.User)
      ) {
        // all invited members are paid users
        setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);
      } else if (
        rolesToInvite.every(
          (role) => role === InternalOrganizationRole.SocialListeningUser,
        )
      ) {
        // Invite all users as social listening users
        await inviteEnterpriseOrgMembers(
          selectedEmails.map((email) => ({
            email,
            role: InternalOrganizationRole.SocialListeningUser,
          })),
        );
        setCurrentView(MemberInviteState.MemberAddedToSocialListening);
      } else {
        setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);
      }
    }
  };

  if (currentView === MemberInviteState.MemberAddedToSocialListening) {
    return (
      <SocialListeningUsersInvited
        customTitle={
          !Object.keys(userRoleData).every(
            (email) =>
              userRoleData[email] ===
              InternalOrganizationRole.SocialListeningUser,
          )
            ? 'For Social Listening Members,'
            : ''
        }
        emails={selectedEmails.filter(
          (email) =>
            userRoleData[email] ===
            InternalOrganizationRole.SocialListeningUser,
        )}
        onDone={onAfterInvite}
      />
    );
  }

  if (currentView === MemberInviteState.ConfirmPaidMembersInvite) {
    return (
      <MemberInviteConfirmation
        customTitle={
          !Object.keys(userRoleData).every(
            (email) =>
              userRoleData[email] === InternalOrganizationRole.User ||
              userRoleData[email] === InternalOrganizationRole.Admin,
          )
            ? 'For “All Plot Features” added members,'
            : ''
        }
        emails={selectedEmails.filter(
          (email) =>
            userRoleData[email] === InternalOrganizationRole.User ||
            userRoleData[email] === InternalOrganizationRole.Admin,
        )}
        onConfirm={async () => {
          await inviteEnterpriseOrgMembers(
            Object.entries(userRoleData).map(([email, role]) => ({
              email,
              role,
            })),
          );
          const uniqueRoles = new Set(Object.values(userRoleData));
          if (uniqueRoles.size === 1) {
            onAfterInvite();
          } else {
            setCurrentView(MemberInviteState.MemberAddedToSocialListening);
          }
        }}
        onCancel={() => {
          setCurrentView(MemberInviteState.EmailSelection);
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        background: theme.colors?.primary.white,
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500,
        maxWidth: 500,
      }}
    >
      <Box
        sx={{
          background: 'rgba(250, 243, 236, 0.5)',
          pb: 4,
          pt: 0,
          '& input': {
            color: hasError ? theme.colors?.utility['pink-3'] : 'inherit',
          },
        }}
      >
        {isSocialListeningEnabled &&
          currentView === MemberInviteState.RoleSelection && (
            <Button
              variant="text"
              onClick={() => {
                setCurrentView(MemberInviteState.EmailSelection);
              }}
              sx={{ p: theme.spacing(2, 2), m: 4, mb: 0, minWidth: 0 }}
              startIcon={<IconLinearArrowLeft size={16} />}
            >
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={600}
              >
                Back
              </Typography>
            </Button>
          )}
        <ManageUsersInput
          sx={{
            py: 2,
            pt:
              isSocialListeningEnabled &&
              currentView === MemberInviteState.RoleSelection
                ? 0
                : 6,
          }}
          searchStr={searchStr}
          // Convert the search string to lowercase to avoid case sensitivity
          onEnter={() => validateAndAddEmail(searchStr.toLowerCase())}
          onSearchStrUpdate={(text) => {
            setSearchStr(text);
          }}
          onInvite={onInviteMembers}
          onRemoveUser={(index) => {
            removeUser(selectedEmails[index]);
          }}
          users={selectedEmails.map(getTempUser)}
          componentProps={{
            tag: { sx: { bgcolor: 'rgba(35, 6, 3, 0.1)' } },
            input: {
              sx: {
                '& .MuiChip-label': {
                  pl: 1,
                },
                '.MuiOutlinedInput-root': {
                  p: `0 !important`,

                  input: {
                    ...theme.typography['headline-xs'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              },
            },
          }}
        />
        {selectedEmails.length > 0 &&
          currentView === MemberInviteState.EmailSelection && (
            <Box ml={3}>
              <OrgRoleSelector
                minimalView
                canUpdate={canUpdateRole}
                viewOnly={!canUpdateRole}
                initialValue={selectedRole}
                onRoleChange={(role) => {
                  setSelectedRole(role);
                  setUserRoleData(
                    selectedEmails.reduce((acc, email) => {
                      acc[email] = role;

                      return acc;
                    }, {} as { [email: string]: InternalOrganizationRole }),
                  );
                }}
              />
            </Box>
          )}
      </Box>
      <Box
        sx={{
          background: 'rgba(250, 243, 236, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          px: 2,
        }}
      >
        {!hasError &&
          !alreadyExistMember &&
          currentView === MemberInviteState.EmailSelection && (
            <ManageUsersList
              users={allUsers}
              selectedEmails={selectedEmails}
              onAddEmail={validateAndAddEmail}
              onRemoveEmail={removeUser}
            />
          )}
        {!hasError &&
          !alreadyExistMember &&
          currentView === MemberInviteState.RoleSelection && (
            <MemberUserRoleSelection
              userRoleData={userRoleData}
              onRoleChange={onRoleChange}
            />
          )}

        {(hasError || alreadyExistMember) && (
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {alreadyExistMember ? (
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[1000]}
                fontWeight={600}
                textAlign="center"
                px={6}
              >
                The member{' '}
                <Box component="span" color={theme.colors?.utility['pink-3']}>
                  {`${getFullName(alreadyExistMember)} (${
                    alreadyExistMember.email
                  })`}
                </Box>{' '}
                you are trying to add is already a part of this organization.
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[1000]}
                  fontWeight={600}
                  textAlign="center"
                >
                  You can only add members with <br />
                  <Box component="span" color={theme.colors?.utility['pink-3']}>
                    {isWorkDomain(user!.organization.domain)
                      ? `name@${user!.organization.domain} `
                      : 'personal email '}
                  </Box>
                  from here.
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body-sm"
                  color={theme.colors?.utility[700]}
                  fontWeight={500}
                >
                  You can invite the guests directly to a <br />
                  collection or a note/post.
                </Typography>
                {selectedEmails.length === 0 && (
                  <Link to={PlotRoutes.juicebox()}>
                    <Typography
                      variant="body-sm"
                      sx={{ textDecoration: 'underline' }}
                      fontWeight={500}
                    >
                      Head to Creative Juicebox
                    </Typography>
                  </Link>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={onInviteMembers}
        sx={{
          border: 'none',
          p: 3,
          backgroundColor: theme.colors?.primary.black,
          fontWeight: 600,
          color: theme.colors?.primary.white,
          '&:hover': {
            backgroundColor: theme.colors?.primary.black,
            fontWeight: 600,
            color: theme.colors?.primary.white,
          },
        }}
      >
        Add members
      </Button>
    </Box>
  );
};
