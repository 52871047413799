import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { CollectionCardView } from 'features/collection';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  useGetCollectionByIdsForDndCollectionDragPreviewQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetCollectionByIdsForDndCollectionDragPreview(
    $input: GetCollectionByIdsInput!
  ) {
    collectionByIds(input: $input) {
      id
      ...CollectionFragmentCollectionCardView
    }
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

export type DndCollectionDragPreviewProps = {
  collectionIds: string[];
};

export const DndCollectionDragPreview = (
  props: DndCollectionDragPreviewProps,
) => {
  const { collectionIds } = props;

  // NOTE: We should be using cache data here
  const { data } = useGetCollectionByIdsForDndCollectionDragPreviewQuery({
    variables: {
      input: {
        collectionIds,
      },
    },
  });
  const collections = data?.collectionByIds || [];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'visible',
        width: 240,
        height: 180,
      }}
    >
      {collections.slice(0, 3).map((collection, index) => {
        return (
          <CollectionCardView
            key={collection.id}
            collection={collection}
            sx={{
              position: 'absolute',
              width: '100%',
              top: index * 10,
              left: index * 10,
              zIndex: 3 - index,
              borderRadius: 4,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        );
      })}
    </Box>
  );
};
