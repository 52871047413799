import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import {
  CUSTOM_COLLECTION,
  useCollectionIdFromParams,
} from 'features/collection';
import {
  PostFiltersForSmartSearch,
  PostFilterType,
  PostFragmentUseNavigatePostSearchParamsFragment,
  PostType,
  SortType,
  useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS = gql`
  fragment PostFragmentUseNavigatePostSearchParams on PostModel {
    id
    type
  }
`;

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForPostNavigationSearchParams(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $before: String
    $sortType: SortType
    $sortBy: SortByInputData
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      before: $before
      sortType: $sortType
      sortBy: $sortBy
    ) {
      data {
        item {
          ...PostFragmentUseNavigatePostSearchParams
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS}
`;

/**
 * This hook aims to provide the next and previous post navigation in the Post Detail page.
 * Therefore, we are relying on postId and collectionId that can be found in the route params.
 */
export const useNavigatePostSearchParams = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const prevLinkRef = useRef<HTMLAnchorElement>(null);
  const nextLinkRef = useRef<HTMLAnchorElement>(null);

  const [prevPost, setPrevPost] = useState<
    PostFragmentUseNavigatePostSearchParamsFragment | undefined
  >();
  const [nextPost, setNextPost] = useState<
    PostFragmentUseNavigatePostSearchParamsFragment | undefined
  >();

  const { collectionId = '' } = useCollectionIdFromParams();
  const { id: postId = '' } = useParams();

  const [getPostsSmartSearchForPaginationForNext] =
    useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery();
  const [getPostsSmartSearchForPaginationForPrev] =
    useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery();

  useEffect(() => {
    setNextPost(undefined);
    setPrevPost(undefined);

    getPost('next').then((post) => {
      if (post) {
        setNextPost(post);
      }
    });

    getPost('prev').then((post) => {
      if (post) {
        setPrevPost(post);
      }
    });
  }, [postId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      // Here we exclude the div element because richtext editor is a div element
      const excludedInputTypes = ['input', 'textarea', 'select', 'div'];

      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        excludedInputTypes.includes(
          document.activeElement.tagName.toLowerCase(),
        );

      if (!isInputFocused) {
        if (event.key === 'ArrowLeft' && prevLinkRef.current) {
          prevLinkRef.current.click();
        } else if (event.key === 'ArrowRight' && nextLinkRef.current) {
          nextLinkRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [prevLinkRef, nextLinkRef]);

  const getPost = async (direction: 'next' | 'prev') => {
    if (!postId) {
      return;
    }

    let _filterType: PostFilterType | undefined;
    let _collectionId: string | undefined;
    // eslint-disable-next-line default-case
    switch (collectionId) {
      case CUSTOM_COLLECTION.ALL_POSTS:
        _filterType = PostFilterType.OrganizationPosts;
        break;
      case CUSTOM_COLLECTION.MY_POSTS:
        _filterType = PostFilterType.MyPosts;
        break;
      case CUSTOM_COLLECTION.SAVED:
        _filterType = PostFilterType.MyFavoritePosts;
        break;
      case CUSTOM_COLLECTION.SAVED_TRENDS:
        _filterType = PostFilterType.MySavedTrends;
        break;
      default:
        _filterType = PostFilterType.OrganizationPosts;
        _collectionId = collectionId;
    }

    // See useJuiceboxFilter for where we are setting the filters in the state
    const state =
      (location.state as {
        filters?: PostFiltersForSmartSearch;
        sortType?: SortType;
      }) || {};
    const existingFilters = state.filters;

    const sortType = state.sortType ?? SortType.DateCreated;
    const filters = {
      ...existingFilters,
      filterType: existingFilters?.filterType ?? _filterType,
      collectionIds: existingFilters?.collectionIds?.length
        ? existingFilters.collectionIds
        : _collectionId
        ? [_collectionId]
        : [],
    };

    const variables = {
      filters,
      sortType,
      take: 1,
      ...(direction === 'next'
        ? { after: postId }
        : {
            before: postId,
          }),
    };

    const { data } =
      direction === 'prev'
        ? await getPostsSmartSearchForPaginationForPrev({
            variables,
            fetchPolicy: 'network-only',
          })
        : await getPostsSmartSearchForPaginationForNext({
            variables,
            fetchPolicy: 'network-only',
          });

    return data?.postsSmartSearch.data?.[0]?.item;
  };

  const renderPrevBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {prevPost && (
          <Link
            to={{
              pathname:
                prevPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: prevPost.id })
                  : PlotRoutes.juice(prevPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={prevLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              left={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowLeft size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  const renderNextBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {nextPost && (
          <Link
            to={{
              pathname:
                nextPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: nextPost.id })
                  : PlotRoutes.juice(nextPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={nextLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              right={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowRight size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  return {
    renderPrevBtn,
    renderNextBtn,
    prevPost,
    nextPost,
  };
};
