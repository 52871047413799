import { MutableRefObject } from 'react';
import {
  FileFilterType,
  GetFiltersSearchForJuiceboxFilterSuggestionViewQuery,
  UserFilterType,
  UserProfileModel,
} from 'graphql/generated';
import { JuiceboxFilter, JuiceboxFilterType } from 'features/juicebox/hooks';
import { Box } from '@mui/material';
import { getFullName } from 'utils/users';
import { theme } from 'styles/theme';
import {
  getDateModifiedState,
  getReactionState,
} from 'features/juicebox/constants';
import { JuiceboxFilterSearchItem } from '../searchItem';

interface Props {
  filtersSearchData: GetFiltersSearchForJuiceboxFilterSuggestionViewQuery['filtersSearch'];
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
  componentProps?: {
    filterSearchItem?: {
      onSelectFilter: (filter: JuiceboxFilter) => void;
    };
  };
}

export const JuiceboxFilterSearchResult = ({
  elRefs,
  filtersSearchData,
  componentProps = {},
}: Props) => {
  let elIndex = 0;
  return (
    <Box display="flex" flexDirection="column" my={-2} tabIndex={0}>
      {filtersSearchData?.createdBy?.map((user, index) => (
        <JuiceboxFilterSearchItem
          key={index}
          label="created by"
          item={user}
          value={getFullName(user)}
          hoverBgColor={theme.colors?.utility['blue-1-new']}
          ref={(el) => {
            elRefs.current[elIndex] = el;
            elIndex++;
          }}
          onSelectItem={() => {
            componentProps.filterSearchItem?.onSelectFilter({
              item: user as unknown as UserProfileModel,
              filterType: JuiceboxFilterType.Users,
              value: user.id,
              itemType: UserFilterType.CreatedBy,
            });
          }}
        />
      ))}
      {filtersSearchData?.sharedBy?.map((user, index) => (
        <JuiceboxFilterSearchItem
          key={index}
          label="sent by"
          item={user}
          value={getFullName(user)}
          hoverBgColor={theme.colors?.utility['blue-1-new']}
          ref={(el) => {
            elRefs.current[elIndex] = el;
            elIndex++;
          }}
          onSelectItem={() => {
            componentProps.filterSearchItem?.onSelectFilter({
              item: user as unknown as UserProfileModel,
              filterType: JuiceboxFilterType.Users,
              value: user.id,
              itemType: UserFilterType.SharedBy,
            });
          }}
        />
      ))}
      {filtersSearchData?.collections?.map((collection, index) => (
        <JuiceboxFilterSearchItem
          key={index}
          label="collection"
          value={collection.name}
          hoverBgColor={theme.colors?.utility['purple-1']}
          ref={(el) => {
            elRefs.current[elIndex] = el;
            elIndex++;
          }}
          onSelectItem={() => {
            componentProps.filterSearchItem?.onSelectFilter({
              filterType: JuiceboxFilterType.PostCollections,
              value: collection.id,
              label: collection.name,
            });
          }}
        />
      ))}
      {filtersSearchData?.type?.map((type, index) => (
        <JuiceboxFilterSearchItem
          key={index}
          label="type"
          value={type}
          hoverBgColor={theme.colors?.utility['teal-1']}
          ref={(el) => {
            elRefs.current[elIndex] = el;
            elIndex++;
          }}
          onSelectItem={() => {
            let filterType = JuiceboxFilterType.Links;
            if (
              [
                JuiceboxFilterType.Uploads,
                FileFilterType.Document,
                FileFilterType.Images,
                FileFilterType.Video,
              ].includes(type as any)
            ) {
              filterType = JuiceboxFilterType.Uploads;
            } else if (type === JuiceboxFilterType.Collections) {
              filterType = JuiceboxFilterType.Collections;
            } else if (type === JuiceboxFilterType.Notes) {
              filterType = JuiceboxFilterType.Notes;
            }
            componentProps.filterSearchItem?.onSelectFilter({
              filterType,
              value: type,
              label: type,
            });
          }}
        />
      ))}
      {filtersSearchData?.reaction?.map((reaction, index) => {
        const { emoji, label } = getReactionState(reaction);
        return (
          <JuiceboxFilterSearchItem
            key={index}
            label="reaction"
            value={`${emoji} ${label}`}
            hoverBgColor={theme.colors?.utility['green-1']}
            ref={(el) => {
              elRefs.current[elIndex] = el;
              elIndex++;
            }}
            onSelectItem={() => {
              componentProps.filterSearchItem?.onSelectFilter({
                filterType: JuiceboxFilterType.Reactions,
                value: emoji,
                label,
              });
            }}
          />
        );
      })}
      {filtersSearchData?.dateModified?.map((date, index) => {
        const { text, value } = getDateModifiedState(date);
        return (
          <JuiceboxFilterSearchItem
            key={index}
            label="modified"
            value={text}
            hoverBgColor={theme.colors?.utility['orange-1']}
            ref={(el) => {
              elRefs.current[elIndex] = el;
              elIndex++;
            }}
            onSelectItem={() => {
              componentProps.filterSearchItem?.onSelectFilter({
                filterType: JuiceboxFilterType.Date,
                value,
                label: text,
              });
            }}
          />
        );
      })}
    </Box>
  );
};
