import { Masonry } from '@mui/lab';
import { useCheckIfCustomCollection } from 'features/collection/hooks';
import {
  CollectionPostDndCollectionCard,
  CollectionPostDndPostCard,
} from 'features/collectionPostDnd/components';
import { useJuiceboxSelectContext } from 'features/juicebox';
import {
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  PostFragmentCollectionPostDndPostCardFragment,
  PostType,
} from 'graphql/generated';
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';

export type CollectionPostDndMasonryViewProps = {
  posts: PostFragmentCollectionPostDndPostCardFragment[];
  collections: CollectionFragmentCollectionPostDndCollectionCardFragment[];
  smartSearchPostIds?: string[];
  layout?: 'default' | 'compact';
  onPostClick?: (
    postId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
  onCollectionClick?: (
    collectionId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
};

export const CollectionPostDndMasonryView = (
  props: CollectionPostDndMasonryViewProps,
) => {
  const {
    posts,
    collections,
    smartSearchPostIds = [],
    layout = 'default',
    onPostClick,
    onCollectionClick,
  } = props;

  const location = useLocation();

  const { collectionId: currentCollectionId = '' } = useParams();
  const isCustomCollection = useCheckIfCustomCollection(currentCollectionId);

  const {
    addPosts: addPostsForSelect,
    addCollections: addCollectionsForSelect,
  } = useJuiceboxSelectContext();

  useEffect(() => {
    addPostsForSelect(posts);
    addCollectionsForSelect(collections);
  }, [JSON.stringify({ posts, collections })]); // eslint-disable-line

  return (
    <Masonry
      columns={{
        xs: 1,
        sm: 2,
        md: layout === 'compact' ? 2 : 3,
        lg: layout === 'compact' ? 2 : 4,
        xl: layout === 'compact' ? 3 : 5,
      }}
      spacing={4}
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {collections.map((collection, index) => {
        return (
          <Link
            key={collection.id}
            to={PlotRoutes.collection(collection.id)}
            style={{ display: 'block' }}
          >
            <CollectionPostDndCollectionCard
              collection={collection}
              onClick={() => {
                onCollectionClick?.(
                  collection.id,
                  index + 1,
                  collections.length,
                );
              }}
            />
          </Link>
        );
      })}
      {posts.map((post, index) => {
        return (
          <Link
            key={post.id}
            to={
              post.type === PostType.Note
                ? PlotRoutes.juiceboxNote({
                    id: post.id,
                    title: post.title,
                  })
                : PlotRoutes.juice(post.id)
            }
            state={{
              ...(location?.state || ({} as any)),
              backgroundLocation: location,
            }}
            style={{ display: 'block' }}
          >
            <CollectionPostDndPostCard
              currentCollectionId={
                isCustomCollection ? undefined : currentCollectionId
              }
              post={post}
              isSmartSearch={smartSearchPostIds?.includes(post.id)}
              onClick={() => {
                onPostClick?.(post.id, index + 1, posts.length);
              }}
            />
          </Link>
        );
      })}
    </Masonry>
  );
};
